// KegelTabelle.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './KegelTabelle.css';

function KegelTabelle() {
  const [kegler, setKegler] = useState([]);
  const [spiele, setSpiele] = useState([]);
  const [ergebnisse, setErgebnisse] = useState([]);
  const [platzierungen, setPlatzierungen] = useState({});
  const [tagessiege, setTagessiege] = useState([]);
  const [gesamtSpezialerfassungen, setGesamtSpezialerfassungen] = useState({
    Pumpen: [],
    Neunen: [],
    Kraenze: []
  });
  const [jahre, setJahre] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    axios.get('/api/kegler')
      .then(response => setKegler(response.data))
      .catch(error => console.error('Error fetching Kegler data:', error));

    axios.get('/api/spiele')
      .then(response => {
        setSpiele(response.data);

        // Extrahiere die Jahre aus den Spielen und setze das aktuellste Jahr als Vorauswahl
        const years = [...new Set(response.data.map(spiel => new Date(spiel.datum).getFullYear()))].sort((a, b) => b - a);
        setJahre(years);
        setSelectedYear(years[0]);
      })
      .catch(error => console.error('Error fetching Spiele data:', error));

    axios.get('/api/ergebnisse')
      .then(response => setErgebnisse(response.data))
      .catch(error => console.error('Error fetching Ergebnisse data:', error));
  }, []);

  useEffect(() => {
    if (kegler.length > 0 && spiele.length > 0 && ergebnisse.length > 0 && selectedYear) {
      calculatePlatzierungen();
      calculateGesamtSpezialerfassungen();
    }
  }, [kegler, spiele, ergebnisse, selectedYear]); // Aktualisierung bei Änderungen

  const calculatePlatzierungen = () => {
    const platzierungenPerSpiel = {};
    const siegesCount = {};
  
    const filteredSpiele = spiele.filter(spiel => {
      const spielJahr = new Date(spiel.datum).getFullYear();
      return spielJahr === selectedYear;
    });
  
    filteredSpiele.forEach(spiel => {
      const spielErgebnisse = ergebnisse.filter(e => e.spiel.id === spiel.id);
      const validErgebnisse = spielErgebnisse.filter(e => e.l1 !== 0 || e.r1 !== 0 || e.l2 !== 0 || e.r2 !== 0);
  
      const totalPoints = validErgebnisse.map(e => ({
        ...e,
        total: e.l1 + e.r1 + e.l2 + e.r2
      }));
  
      totalPoints.sort((a, b) => b.total - a.total);
  
      const platzierungen = [];
      let lastTotal = null;
      let lastRank = 0;
  
      totalPoints.forEach((e, index) => {
        if (e.total !== lastTotal) {
          lastRank = index + 1;
        }
  
        platzierungen.push({
          ...e,
          platzierung: lastRank
        });
  
        if (lastRank === 1) {
          if (siegesCount[e.kegler.id]) {
            siegesCount[e.kegler.id]++;
          } else {
            siegesCount[e.kegler.id] = 1;
          }
        }
  
        lastTotal = e.total;
      });
  
      platzierungenPerSpiel[spiel.id] = platzierungen;
    });
  
    const sortedSieges = Object.entries(siegesCount)
      .sort((a, b) => b[1] - a[1]) // Sortiere nach der Anzahl der Siege
      .map(([keglerId, count], index, arr) => {
        // Setze denselben Platz für gleiche Ergebnisse
        const lastEntry = arr[index - 1];
        const platz = lastEntry && lastEntry[1] === count ? arr[index - 1][2] : index + 1;
        return [keglerId, count, platz];
      });
  
    const top8Sieges = sortedSieges.slice(0, 8).map(([keglerId, count, platz]) => {
      const keglerName = kegler.find(k => k.id === parseInt(keglerId))?.name || 'Unbekannt';
      return { keglerName, count, platz };
    });
  
    setPlatzierungen(platzierungenPerSpiel);
    setTagessiege(top8Sieges);
  };
  const calculateGesamtSpezialerfassungen = () => {
    const spezialCounts = {
      Pumpen: {},
      Neunen: {},
      Kraenze: {}
    };
  
    // Filter Ergebnisse nach dem ausgewählten Jahr
    const filteredErgebnisse = ergebnisse.filter(e => {
      const spiel = spiele.find(sp => sp.id === e.spiel.id);
      return spiel && new Date(spiel.datum).getFullYear() === selectedYear;
    });
  
    filteredErgebnisse.forEach(e => {
      if (!spezialCounts.Pumpen[e.kegler.id]) {
        spezialCounts.Pumpen[e.kegler.id] = 0;
      }
      spezialCounts.Pumpen[e.kegler.id] += e.pumpen;
  
      if (!spezialCounts.Neunen[e.kegler.id]) {
        spezialCounts.Neunen[e.kegler.id] = 0;
      }
      spezialCounts.Neunen[e.kegler.id] += e.neunen;
  
      if (!spezialCounts.Kraenze[e.kegler.id]) {
        spezialCounts.Kraenze[e.kegler.id] = 0;
      }
      spezialCounts.Kraenze[e.kegler.id] += e.kraenze;
    });
  
    const calculateTopList = (counts) => {
      return Object.entries(counts)
        .sort((a, b) => b[1] - a[1]) // Sortiere nach der Anzahl
        .map(([keglerId, count], index, arr) => {
          // Setze denselben Platz für gleiche Ergebnisse
          const lastEntry = arr[index - 1];
          const platz = lastEntry && lastEntry[1] === count ? arr[index - 1][2] : index + 1;
          return [keglerId, count, platz];
        })
        .map(([keglerId, count, platz]) => {
          const keglerName = kegler.find(k => k.id === parseInt(keglerId))?.name || 'Unbekannt';
          return { keglerName, count, platz };
        });
    };
  
    const top5Pumpen = calculateTopList(spezialCounts.Pumpen).slice(0, 5);
    const top5Neunen = calculateTopList(spezialCounts.Neunen).slice(0, 5);
    const top5Kraenze = calculateTopList(spezialCounts.Kraenze).slice(0, 5);
  
    setGesamtSpezialerfassungen({
      Pumpen: top5Pumpen,
      Neunen: top5Neunen,
      Kraenze: top5Kraenze
    });
  };
  
    return (
    <div className="kegel-tabelle">
      <h2>Kegelklub Blau-Weiß</h2>

      {/* Dropdown zur Jahresauswahl */}
      <div className="jahr-auswahl">
        <label htmlFor="jahr">Jahr auswählen:</label>
        <select
          id="jahr"
          value={selectedYear}
          onChange={(e) => setSelectedYear(parseInt(e.target.value))}
        >
          {jahre.map(year => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
      </div>

      <div className="uebersicht">
        <div className="spezial-tabellen">
          <div>
            <h3>Beste Kegler (Tagessiege)</h3>
            <table className="fixed-header small-table">
              <thead>
                <tr>
                  <th>Platz</th>
                  <th>Kegler</th>
                  <th>Anzahl Tagessiege</th>
                </tr>
              </thead>
              <tbody>
                {tagessiege.map((s, index) => (
                 <tr key={index}>
                   <td>{s.platz}</td>
                   <td>{s.keglerName}</td>
                   <td>{s.count}</td>
                 </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div>
            <h3>Top 5 Pumpen</h3>
            <table className="fixed-header small-table">
              <thead>
                <tr>
                  <th>Platz</th>
                  <th>Kegler</th>
                  <th>Anzahl</th>
                </tr>
              </thead>
              <tbody>
  {gesamtSpezialerfassungen.Pumpen.map((s, index) => (
    <tr key={index}>
      <td>{s.platz}</td>
      <td>{s.keglerName}</td>
      <td>{s.count}</td>
    </tr>
  ))}
</tbody>
            </table>
          </div>

          <div>
            <h3>Top 5 Neunen</h3>
            <table className="fixed-header small-table">
              <thead>
                <tr>
                  <th>Platz</th>
                  <th>Kegler</th>
                  <th>Anzahl</th>
                </tr>
              </thead>
              <tbody>
  {gesamtSpezialerfassungen.Neunen.map((s, index) => (
    <tr key={index}>
      <td>{s.platz}</td>
      <td>{s.keglerName}</td>
      <td>{s.count}</td>
    </tr>
  ))}
</tbody>

            </table>
          </div>

          <div>
            <h3>Top 5 Kränze</h3>
            <table className="fixed-header small-table">
              <thead>
                <tr>
                  <th>Platz</th>
                  <th>Kegler</th>
                  <th>Anzahl</th>
                </tr>
              </thead>
              <tbody>
  {gesamtSpezialerfassungen.Kraenze.map((s, index) => (
    <tr key={index}>
      <td>{s.platz}</td>
      <td>{s.keglerName}</td>
      <td>{s.count}</td>
    </tr>
  ))}
</tbody>              
            </table>
          </div>
        </div>
      </div>

      {spiele
        .filter(spiel => new Date(spiel.datum).getFullYear() === selectedYear)
        .map(spiel => (
          <div key={spiel.id} className="spiel-abschnitt">
            <h3>{spiel.beschreibung} - {new Date(spiel.datum).toLocaleDateString()}</h3>
            <table>
              <thead>
                <tr>
                  <th>Platzierung</th>
                  <th>Kegler</th>
                  <th>l1</th>
                  <th>r1</th>
                  <th>l2</th>
                  <th>r2</th>
                  <th>Gesamt</th>
                  <th>Pumpen</th>
                  <th>Neunen</th>
                  <th>Kränze</th>
                </tr>
              </thead>
              <tbody>
                {platzierungen[spiel.id] && platzierungen[spiel.id].map((p, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                    <td>{p.platzierung}</td>
                    <td>{kegler.find(k => k.id === p.kegler.id)?.name || 'Unbekannt'}</td>
                    <td>{p.l1}</td>
                    <td>{p.r1}</td>
                    <td>{p.l2}</td>
                    <td>{p.r2}</td>
                    <td>{p.total}</td>
                    <td>{p.pumpen || 0}</td>
                    <td>{p.neunen || 0}</td>
                    <td>{p.kraenze || 0}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
    </div>
  );
}

export default KegelTabelle;
